import Swal from 'sweetalert2'
export default function copyData(item) {
  console.log('item',item);
  item.details = item.details.filter(ele => !ele.is_add_on && ele.kitchen_id)
  let toBeCopied = ``
  let emptyLine = '\n'
  let l1 = `${item.order_number ? `Order ID: ${item.order_number}` : ''}\n`
  let l2 = `${
    item.user.first_name || item.user.last_name
      ? `Customer Name: ${item.user.first_name || ''} ${item.user.last_name || ''}`
      : ''
  }\n`
  let l3 = `${item.user.mobile_number ? `Mobile Number: ${item.user.mobile_number}` : ''}\n`
  let l4 = `${item.time && item.time != 0 ? `Delivery Time Slot: ${item.time}` : `Delivery Date & Time: ${item.date}`}\n`
  let l41 = `${item.is_paid ? `Payment Status: Paid` : `Payment Status: Unpaid`}\n`
  let l42 = `${item.payment_method == 1 ? `Payment Method: Cash` :item.payment_method == 2 ? `Payment Method: Wallet` : item.payment_method == 3 ? `Payment Method: Credit card` : ''}\n`
  let l6 = `${
    item.payment_method == 1
      ? `Total: ${item.total_price || 0} JOD`
      : item.payment_method == 2
      ? `Total: ${item.total_points || 0} Points`
      : ''
  }\n`

  toBeCopied += l1.trimStart()
  toBeCopied += l2.trimStart()
  toBeCopied += l3.trimStart()
  toBeCopied += l4.trimStart()
  toBeCopied += l41.trimStart()
  toBeCopied += l42.trimStart()
  toBeCopied += l6.trimStart()
  item.details.map((detailedItem, key) => {
    let itemText1 = `${detailedItem.item_name_ar ? `Item name${key + 1}: ${detailedItem.item_name_ar}` : ''}\n`
    let itemText2 = `${detailedItem.quantity || detailedItem.quantity == 0 ? `Quantity: ${detailedItem.quantity}` : 0}\n`
    let itemText3 = `${detailedItem.add_ons?.length ? `Add-ons: ${detailedItem.add_ons.map((list) => list.name).join(", ")}` : ''}\n`
    let itemText4 = `${detailedItem.used_in_all?.length ? `Soft drink: ${detailedItem.used_in_all.map((list) => list.name).join(", ")}` : ''}\n`
    let itemText5 = `${detailedItem.kitchen_name ? `Kitchen name: ${detailedItem.kitchen_name}` : ''}\n`
    let itemText6 = `${detailedItem.kitchen_location ? `Kitchen Location: ${detailedItem.kitchen_location}` : ''}\n`

    toBeCopied += emptyLine
    toBeCopied += itemText1.trimStart()
    toBeCopied += itemText2.trimStart()
    toBeCopied += itemText3.trimStart()
    toBeCopied += itemText4.trimStart()
    toBeCopied += itemText5.trimStart()
    toBeCopied += itemText6.trimStart()
  })
  let l7 = `${
    item.location.latitude && item.location.longitude
      ? `Drop off Location Link: https://www.google.com/maps/?q=${item.location.latitude},${item.location.longitude}`
      : ''
  }\n`
  toBeCopied += emptyLine
  let l8 = `${item.location.neighbourhood ? `Neighborhood: ${item.location.neighbourhood}` : ''}\n`
  let l9 = `${item.location.street_name ? `Street Name: ${item.location.street_name}` : ''}\n`
  let l10 = `${item.location.building_number ? `Building Number: ${item.location.building_number}` : ''}\n`
  let l11 = `${item.note != null ? `Note: ${item.note}` : ''}\n`

  toBeCopied += l7.trimStart()
  toBeCopied += l8.trimStart()
  toBeCopied += l9.trimStart()
  toBeCopied += l10.trimStart()
  toBeCopied += emptyLine
  toBeCopied += l11.trimStart()

  navigator.clipboard
    .writeText(toBeCopied.trimEnd())
    .then(() => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Copied Successfully',
        showConfirmButton: true,
      })
    })
    .catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Something Went wrong',
      })
    })
}
