<template>
  <div id="messages-list">
    <!-- app drawer -->

    <!-- orders total card -->
    <v-row class="mb-5">
      <v-col v-for="total in ordersTotalLocal" :key="total.total" cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Search &amp; Filter </v-card-title>
      <v-divider class="mt-4"></v-divider>
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="codes-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <!-- <v-btn color="primary" class="mb-4 me-3"
            @click.stop="isAddNewOrderSidebarActive = !isAddNewOrderSidebarActive">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Order</span>
          </v-btn>-->
          <div class="mb-4 mr-4 d-flex align-center">
            <v-menu
              v-model="isStartDateMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="first_date"
                  label="Start Date"
                  placeholder="yyyy-mm-dd"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker no-title v-model="first_date" @input="isStartDateMenuOpen = false"></v-date-picker>
            </v-menu>
          </div>
          <div class="mb-4 mr-4 d-flex align-center justify-end">
            <v-menu
              v-model="isEndDateMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              eager
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="last_date"
                  label="End Date"
                  placeholder="yyyy-mm-dd"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker no-title v-model="last_date" @input="isEndDateMenuOpen = false"></v-date-picker>
            </v-menu>
          </div>
          <v-btn color="secondary" outlined class="mb-4" @click="downLoadxlsx">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        id="table"
        v-model="selectedRows"
        :headers="tableColumns"
        :items="ordersListTable"
        :options.sync="options"
        :server-items-length="totalOrdersListTable"
        :loading="loading"
        @refetch-data="fetchOrders()"
      >
        <!-- Name  -->
        <template #[`item.order_number`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-order-delivered-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.order_number }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- Delivered Time -->
        <template #[`item.time`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              {{ item.time && item.time != 0 ? item.time : item.date }}
            </div>
          </div>
        </template>

        <!-- Time Moved To Delivered -->
        <template #[`item.updated_at`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              {{ moment(item.updated_at) }}
            </div>
          </div>
        </template>

        <!-- Date & Time -->
        <template #[`item.created_at`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ moment(item.created_at) }}
            </div>
          </div>
        </template>
        <!-- payment method-->
        <template #[`item.payment_method`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              {{
                item.payment_method == 1
                  ? 'Cash'
                  : item.payment_method == 2
                  ? 'Balance'
                  : item.payment_method == 3
                  ? 'Payment'
                  : '___'
              }}
            </div>
          </div>
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-order-delivered-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="copyRow(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                  <span>Copy Data</span>
                </v-list-item-title>
              </v-list-item>

              <!--<v-list-item >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiSquareEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
mdiAccountOutline, mdiContentCopy, mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant,
mdiEye,
mdiFileDocumentOutline, mdiPlus,
mdiSquareEditOutline
} from '@mdi/js'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import moment from 'moment'

// sidebar
import { export_table_to_excel } from '@/toExcel'
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import OrdersStoreModule from '../OrdersStoreModule'
import useOrdersList from './useOrdersList'
import copyData from '@/copyData'

export default {
  methods: {
    moment: date => {
      return moment(date).locale('JO').format('MMM DD YYYY , HH:mm')
    },
  },
  setup(props, { emit }) {
    const ORDERS_APP_STORE_MODULE_NAME = 'app-orders'

    // Register module
    if (!store.hasModule(ORDERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDERS_APP_STORE_MODULE_NAME, OrdersStoreModule)
    }
    onMounted(() => {
      options.value.status = 3
    })
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDERS_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDERS_APP_STORE_MODULE_NAME)
    })
    const isEndDateMenuOpen = ref(false)
    const isStartDateMenuOpen = ref(false)
    const {
      last_date,
      first_date,
      ordersListTable,
      tableColumns,
      searchQuery,
      totalOrdersListTable,
      loading,
      options,
      ordersTotalLocal,
      selectedRows,
      planFilter,
      fetchOrders,
    } = useOrdersList()

    const isAddNewOrderSidebarActive = ref(false)

    const planOptions = [{ title: 'ID#', value: 'order_number' }]

    const handleCancel = () => {
      const id = this.$route.params.id
      let data = {
        order_id: id,
        reason: document.querySelector('.swal2-input').value,
      }
      Swal.fire({
        title: 'Are you sure you want to cancel this order?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-orders/cancelOrder', data)
            .then(() => {
              ordersListTable.value = ordersListTable.value.filter(item => item.id !== id)
              Swal.fire('Canceled!', 'entry has been canceled.', 'success')
            })
            .catch(error => {
              Swal.fire({
                icon: 'error',
                title: `err`,
                text: error,
              })
            })
        }
      })
    }
    const copyRow = (data) => {
      copyData(data)
    } 
    const downLoadxlsx = () => {
      export_table_to_excel('table', tableColumns, 'OrdersDeliveredList')
    }

    return {
      first_date,
      last_date,
      isStartDateMenuOpen,
      isEndDateMenuOpen,
      downLoadxlsx,
      ordersListTable,
      tableColumns,
      searchQuery,
      totalOrdersListTable,
      loading,
      options,
      ordersTotalLocal,
      isAddNewOrderSidebarActive,
      selectedRows,
      planOptions,
      planFilter,
      avatarText,
      fetchOrders,
      handleCancel,
      copyRow,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiContentCopy,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
